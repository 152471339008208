import * as Yup from "yup";
import checkout from "./formCompanion";

const {
  formField: { propertyCode, inRoomDiningPropertyId },
} = checkout;

const validationsCompanion = [
  Yup.object().shape({
    [propertyCode.name]: Yup.string().required(propertyCode.errorMsg),
    [inRoomDiningPropertyId.name]: Yup.string().when(["inRoomDining", "diningCompany"], {
      is: (inRoomDining, diningCompany) => inRoomDining === true && diningCompany === "digi",
      then: () => Yup.string().required(inRoomDiningPropertyId.errorMsg),
    }),
  }),
];

export default validationsCompanion;
