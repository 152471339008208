/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import axios from "axios";
import { createFormData } from "components/helper";
import {
  SET_LOCATION_LOADING,
  CLEAR_ERRORS,
  LOCATION_ADD_SUCCESS,
  LOCATION_FAIL,
  SET_LOCATION_APPEARANCE,
  UPDATE_LOCATION,
  SLIDE_LOCATION_ADD_SUCCESS,
  LOCATION_SLIDERS_FAIL,
  SLIDE_LOCATION_UPDATE,
  EDIT_ERROR,
  SET_REFRESH_LOADING,
  LOCATION_JOBS_ADD_SUCCESS,
  WIFI_LOCATION_ADD_SUCCESS,
  LOCATION_WIFI_FAIL,
  PUBLISH_UNPUBLISH,
} from "./Types";

// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;
const env = process.env.REACT_APP_ENV;

// Add Location
export const addLocation = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  // const formData = createFormData({ ...data, logo });

  const form = new FormData();
  // if (image) form.append('images', image);
  // if(data.name) {data.name = data.name.trim()}

  form.append("name", data.name.trim());
  form.append("enableServices", data.enableServices);

  data.email?.map((email) => {
    if (email !== "") {
      form.append("email", email?.trim());
      return email;
    }
    return 1;
  });

  form.append("serviceTitle", data.serviceTitle.trim());
  form.append("dinningLabel", data.dinningLabel);
  form.append("primary", data.primary);
  form.append("background", data.background);
  form.append("button", data.button);
  form.append("address", data.address.trim());
  form.append("city", data.city.trim());
  form.append("region", data.region);
  form.append("country", data.country);
  form.append("numRooms", data.numRooms);
  form.append("trn", data.trn);
  form.append("partner", data.partner);
  if (data.trendTitle) form.append("trendTitle", data.trendTitle);
  if (data.trendSubTitle) form.append("trendSubTitle", data.trendSubTitle);

  form.append("primaryDestination", data.primaryDestination);

  if (data.travelDesk !== "") form.append("travelDesk", data.travelDesk);
  form.append("template", data.template);
  form.append("lat", data.lat);
  form.append("lng", data.lng);
  form.append("top", data.top);
  form.append("disclaimer", data.disclaimer);
  if (data.disclaimerText) form.append("disclaimerText", JSON.stringify(data.disclaimerText));
  if (image) {
    image.path = image?.name;
    form.append("images", image);
    form.append("hasLogo", true);
  }
  data.images?.map((file) => {
    form.append("images", file);
    return file;
  });

  data.destinations?.map((destination) => {
    form.append("destinations", destination?.value);
    return destination;
  });

  try {
    const res = await axios.post(`${URL}/locations`, form, config);
    dispatch({
      type: LOCATION_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOCATION_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Update Location
export const updateLocation = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = createFormData({
    name: data.name?.trim(),
    email: data.email,
    enableServices: data.enableServices,
    serviceJobs: data.serviceJobs,
    serviceTitle: data.serviceTitle?.trim(),
    dinningLabel: data.dinningLabel,
    primary: data.primary,
    background: data.background,
    button: data.button,
    showSlider: data.showSlider,
    showGuestCompanion: data.showGuestCompanion,
    slideDuration: data.slideDuration,
    template: data.template,
    address: data.address?.trim(),
    city: data.city?.trim(),
    region: data?.region?.trim(),
    country: data?.country?.trim(),
    numRooms: data.numRooms,
    trn: data.trn,
    partner: data.partner,
    lat: data.lat,
    lng: data.lng,
    top: data.top,
    tier: data?.tier?.value,
    disclaimer: data.disclaimer,
    disclaimerText:
      typeof data.disclaimerText === "object"
        ? JSON.stringify(data.disclaimerText)
        : data.disclaimerText,
    ...(data.geoLocation && {
      address: data.geoLocationData.address,
      city: data.geoLocationData.city,
      region: data.geoLocationData.region,
      country: data.geoLocationData.country,
      lat: data.geoLocation.coordinates[1],
      lng: data.geoLocation.coordinates[0],
    }),
  });
  if (data.trendTitle) form.append("trendTitle", data.trendTitle);
  if (data.trendSubTitle) form.append("trendSubTitle", data.trendSubTitle);
  if (data?.trendTitle?.length === 0) {
    form.append("trendTitle", "");
  }
  if (data?.trendSubTitle?.length === 0) {
    form.append("trendSubTitle", "");
  }
  if (data.travelDesk?._id) form.append("travelDesk", data.travelDesk?._id || data.travelDesk);
  if (data?.travelDesk?.length === 0 || data?.travelDesk === null) form.append("travelDesk", "");
  if (data?.travelDesk?.length > 0 && typeof data?.travelDesk === "string")
    form.append("travelDesk", data?.travelDesk);
  if (data?.numRooms?.length === 0) {
    form.append("numRooms", "");
  }
  if (data?.trn?.length === 0) {
    form.append("trn", "");
  }
  if (data.primaryDestination)
    form.append(
      "primaryDestination",
      typeof data.primaryDestination === "string"
        ? data.primaryDestination
        : data.primaryDestination?.id || data.primaryDestination?._id
    );
  data.destinations?.map((destination) => {
    form.append("destinations", destination?.value || destination?.id);
    return destination;
  });
  if (data?.serviceTitle?.length === 0) {
    form.append("serviceTitle", "");
  }
  if (data?.dinningLabel?.length === 0) {
    form.append("dinningLabel", "");
  }
  if (data?.email?.length === 0) {
    form.append("email", "");
  }
  if (data?.tripPlanner !== undefined) {
    form.append("tripPlanner", data.tripPlanner);
  }
  if (image) {
    // For logo
    // image.path = image?.name;
    form.append("images", image);
    form.append("hasLogo", true);
  }
  // to tell backend that images were cleared
  if (data.images?.length === 0) form.append("cleared", true);
  data.images?.map((file) => {
    form.append("images", file);
    return file;
  });

  try {
    const res = await axios.patch(`${URL}/locations/${data._id}`, form, config);

    dispatch({
      type: UPDATE_LOCATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOCATION_FAIL,
      payload: err?.response.data.message,
    });
  }
};

export const getLocationTranslations = (id) =>
  factory.get(
    `${URL}/locations/${id}/translations?locale=ar`,
    "GET_LOCATION_TRANSLATION",
    "LOCATION_FAIL"
  );

export const updateLocationTranslations = (data, id) =>
  factory.patch(
    {
      name: data.name,
      trendTitle: data.trendTitle,
      trendSubTitle: data.trendSubTitle,
    },
    `${URL}/locations/${id}/translations?locale=ar`,
    "UPDATE_LOCATION_TRANSLATION",
    "LOCATION_FAIL"
  );

// Delete Location - set active as false
export const deleteLocation = (id) =>
  factory.patch({ active: false }, `${URL}/locations/${id}`, "DELETE_LOCATION", "LOCATION_FAIL");

// Set Location for Appearance
export const setLocation = (location) => ({
  type: SET_LOCATION_APPEARANCE,
  payload: location,
});

// Get All Locations

export const getLocations = (extraParam) =>
  factory.get(
    `${URL}/locations?limit=1000&sort=name&dir=asc&fields=name,geoLocationData,numRooms,createdAt,status,tier,slug${
      extraParam ? `${extraParam}` : ""
    }`,
    "GET_LOCATIONS",
    "LOCATION_FAIL"
  );

// get One Location
export const getOneLocation = (id) =>
  factory.get(`${URL}/locations/${id}`, "GET_ONE_LOCATION", "LOCATION_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_LOCATION_LOADING });

// Get Partner Locations

export const getPartnerLocations = (partner, extraParam) =>
  factory.get(
    `${URL}/partners/${partner}/locations?sort=name&dir=asc${extraParam ? `${extraParam}` : ""}`,
    "GET_LOCATIONS",
    "LOCATION_FAIL"
  );

export const getUserPartnerLocations = (extraParam) =>
  factory.get(
    `${URL}/partners/locations?sort=name&dir=asc${extraParam ? `${extraParam}` : ""}`,
    "GET_USER_PARTNER_LOCATIONS",
    "LOCATION_FAIL"
  );

export const addJobs = (locationId, data, selectedTab) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const payload = {
    guestRequestType: data?.company,
    guestRequestPropertyId: data?.propertyCode,
    jobs: data?.serviceJobs,
    showGuestCompanion: data?.showGuestCompanion,
    apikey: data?.apikey || "",
    url: data?.url || "",
    portalId: data?.portalId || "",
    diningId: data?.diningId || "",
  };
  const payloadInRoom = {
    inRoomDining: data?.inRoomDining,
    inRoomDiningPropertyId: data?.inRoomDiningPropertyId,
  };

  try {
    const res = await axios.post(
      `${URL}/locations/${locationId}/companion`,
      selectedTab === 0 ? payload : payloadInRoom,
      config
    );

    dispatch({
      type: LOCATION_JOBS_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOCATION_FAIL,
      payload: err?.response.data.message,
    });
  }
};
// Delete Partner - set active as false
export const deletePartner = (id) =>
  factory.patch(
    { active: false },
    `${URL}/locations/${id}/companion`,
    "DELETE_PARTNER",
    "PARTNER_FAIL"
  );

// Add Location Slide
export const addLocationWifi = (data, id) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  if (data.title) form.append("title", data.title);
  if (data.subTitle) form.append("subTitle", data.subTitle);
  if (data.menus?.length > 0)
    data.menus?.map((menu) => {
      form.append("menus", menu?.value || menu?._id);
      return menu?.value || menu?._id;
    });

  if (data.exploreUrl) form.append("exploreUrl", data.exploreUrl);
  if (data.chat !== undefined) form.append("chat", data.chat);
  if (data.logo) form.append("logo", data.logo ? data.logo : "");

  try {
    const res = await axios.post(`${URL}/locations/${id}/wifi`, form, config);
    dispatch({
      type: WIFI_LOCATION_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOCATION_WIFI_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Add Location Slide
export const addLocationSlide = (data, id) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("title", data.title.trim());
  form.append("subTitle", data.subTitle.trim());
  form.append("ctaLabel", data.ctaLabel.trim());
  form.append("ctaUrl", data.ctaUrl.trim());
  form.append("startDate", data.startDate ? data.startDate.split("T")[0] : "");
  form.append("endDate", data.endDate ? data.endDate.split("T")[0] : "");
  form.append("slideDuration", data.slideDuration);
  data.images?.map((file) => {
    form.append("images", file);
    return file;
  });

  try {
    const res = await axios.post(`${URL}/locations/slide/${id}`, form, config);
    dispatch({
      type: SLIDE_LOCATION_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOCATION_SLIDERS_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Update Location Slide
export const updateLocationSlide = (data, id) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("title", data.title.trim());
  form.append("subTitle", data.subTitle.trim());
  form.append("ctaLabel", data.ctaLabel.trim());
  form.append("ctaUrl", data.ctaUrl.trim());
  form.append("startDate", data.startDate ? data.startDate.split("T")[0] : "");
  form.append("endDate", data.endDate ? data.endDate.split("T")[0] : "");
  form.append("slideDuration", data.slideDuration);
  form.append("index", data._id);
  data.images?.map((file) => {
    form.append("images", file);
    return file;
  });

  try {
    const res = await axios.patch(`${URL}/locations/slide/${id}`, form, config);
    dispatch({
      type: SLIDE_LOCATION_UPDATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOCATION_SLIDERS_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Delete Location Slider
export const deleteLocationSlider = (id, index) =>
  factory.patch(
    { index },
    `${URL}/locations/slide/${id}?delete=true`,
    "DELETE_LOCATION_SLIDER",
    "LOCATION_SLIDERS_FAIL"
  );

export const publishUnpublishLoc = (id, status, tier) => async (dispatch) => {
  try {
    const updatedStatus =
      status === "published"
        ? "archived"
        : status === "draft"
        ? "pending"
        : (status === "archived" || status === "pending") && "published";

    const response = await axios.patch(`${URL}/locations/status/${id}`, {
      status: updatedStatus,
      ...((status === "archived" || status === "pending") &&
        tier?.value &&
        typeof tier === "object" && {
          tier: tier?.value,
        }),
    });

    dispatch({
      type: PUBLISH_UNPUBLISH,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_ERROR,
      paload: err,
    });
  }
};

export const refreshLocation = (slug) => {
  let subdomain = "";

  if (env === "sandbox") {
    subdomain = "https://api.sandbox.lokalee.app/services";
  } else if (env === "production") {
    subdomain = "https://api.lokalee.app/services";
  } else if (env === "staging") {
    subdomain = "https://api.staging.lokalee.app/services";
  } else {
    subdomain = "http://localhost:8003";
  }

  const url = `${subdomain}/v2/cron/boxes${slug ? `?location=${slug}` : ""}`;

  return factory.get(url, "REFRESH_LOCATION", "REFRESH_LOCATION_FAIL");
};

// Set Refresh Loading
export const setRefreshLoading = () => ({ type: SET_REFRESH_LOADING });
