import React from "react";
import { Text } from "@react-email/components";
import PropTypes from "prop-types";

const IfYouNeedSupportSection = ({ showTitle }) => {
  // To prevent blinking when user clicks to open the external email app
  const handleEmailClick = (e) => {
    e.preventDefault();
    window.location.href = "mailto:cs@lokalee.app";
  };

  return (
    <>
      {showTitle && (
        <Text
          style={{
            fontSize: "20px", // 'text-[20px]'
            lineHeight: "23px", // 'leading-[23px]'
            fontWeight: "700", // 'font-bold'
            color: "#000000", // 'text-black'
            textAlign: "center", // 'text-center'
            marginBottom: "0.5rem", // 'mb-2'
          }}
        >
          If you need any assistance:
        </Text>
      )}

      <table
        width="100%"
        style={{
          color: "#ffffff",
          padding: "15.5px",
          textAlign: "center",
          backgroundColor: "#BAB9B9",
        }}
        cellPadding="0"
        cellSpacing="0"
        role="presentation"
      >
        <tbody>
          <tr>
            <td style={{ textAlign: "center" }}>
              <img
                src="https://email-assets.lokalee.app/email/emailImages/inbox.png"
                alt="Inbox"
                style={{
                  width: "33px",
                  height: "20px",
                  display: "block",
                  margin: "0 auto",
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center", paddingTop: "4px" }}>
              <p
                style={{
                  fontSize: "11px",
                  lineHeight: "15px",
                  fontWeight: "700",
                  color: "#ffffff",
                  margin: "0",
                }}
              >
                Email us:
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="mailto:cs@lokalee.app"
                onClick={handleEmailClick}
                style={{
                  display: "flex",
                  fontSize: "11px",
                  marginTop: "2px",
                  color: "#ffffff",
                  fontWeight: "700",
                  lineHeight: "15px",
                  textDecoration: "none",
                  justifyContent: "center",
                }}
              >
                cs@lokalee.app
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
IfYouNeedSupportSection.defaultProps = {
  showTitle: true,
};

IfYouNeedSupportSection.propTypes = {
  showTitle: PropTypes.bool,
};
export default IfYouNeedSupportSection;
