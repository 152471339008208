/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

import PropTypes from "prop-types";
import AsyncSelect from "react-select";
import React, { useEffect } from "react";

const systemOptions = [
  { label: "GuestApp", value: "guestapp" },
  { label: "Service Request", value: "servicerequest" },
  { label: "My Menu", value: "myMenu" },
  { label: "Sky Software Menu", value: "skySoftware" },
];

const ServiceJobsSystemPicker = ({ systemV, setFieldValue, company }) => {
  useEffect(() => {
    switch (company) {
      case "sky":
        setFieldValue("system", "guestapp");
        setFieldValue("diningSystem", "skySoftware");
        break;
      case "messagebox":
        setFieldValue("system", "servicerequest");
        break;
      case "digi":
        setFieldValue("diningSystem", "myMenu");
        break;
      default:
        setFieldValue("system", "");
        break;
    }
  }, [company]);

  return (
    <div>
      <AsyncSelect
        isDisabled
        options={systemOptions}
        placeholder="Select a system"
        value={systemV ? systemOptions.find((item) => item.value === systemV) : null}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor,
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow,
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor,
            },
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

// Setting default props
ServiceJobsSystemPicker.defaultProps = {
  systemV: "",
  company: "",
};

// Prop-types for ServiceJobsSystemPicker
ServiceJobsSystemPicker.propTypes = {
  systemV: PropTypes.string,
  company: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

export default ServiceJobsSystemPicker;
