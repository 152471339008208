/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */

// Libraries
import PropTypes from "prop-types";
import { format } from "date-fns";
import draftToHtml from "draftjs-to-html";
import { Fragment, useEffect, useState } from "react";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { capitalizeFirstLetter, formatString } from "components/helper";

// Material UI components
import { Card, Chip, ImageList, ImageListItem, Avatar } from "@mui/material";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import OpeningHoursList from "components/OpeningHoursList";
import SlidersList from "components/SlidersList";
import MDButton from "components/MDButton";
import styled from "@emotion/styled";
import Taxi from "assets/images/Taxi.svg";
import Train from "assets/images/TrainMetro.svg";
import Bus from "assets/images/Bus.svg";
import ConciergePromocodes from "pages/dashboards/concierge/components/Promocodes";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneUser } from "store/actions/userActions";
import PickupPointsList from "components/PickupPointsList";
import ItineraryItemsList from "components/ItineraryItemsLists";
import LanguagesDetailsList from "components/LanguagesDetailsList";
import AvailabilitieSchedulesDetailsList from "components/AutoComplete/AvailabilitiesSchedulesDetailsList";
import CopytoClipboard from "components/CopytoClipboard";

const BigAvatar = styled(Avatar)`
  width: 74px;
  height: 74px;
`;
const DetailList = ({ title, data, conciergeDetailsPage, bannerDetailsPage }) => {
  const dispatch = useDispatch();
  const { user_id } = useParams();

  const hashtagConfig = {
    trigger: "#",
    separator: " ",
  };
  const [markup, setMarkup] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const user = useSelector((state) => state.users.specificUser);
  const userConcierge = useSelector((state) => state.users.user);

  useEffect(() => {
    if (user_id && conciergeDetailsPage) {
      dispatch(getOneUser(user_id));
    }
  }, [user_id]);

  // Convert the array of image files or image links to URLs
  useEffect(() => {
    const urls = data?.images?.map((item) => {
      if (typeof item === "string") {
        // Handle image links (array of strings)
        return item;
      }
      if (item instanceof File) {
        // Handle image files (array of File objects)
        return URL.createObjectURL(item);
      }
      return null; // Return null for non-matching items
    });

    setImageUrls(urls);

    // Clean up the URLs when the component is unmounted
    return () => {
      urls?.forEach((url) => {
        if (!url?.startsWith("blob:")) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [data?.images]);

  useEffect(() => {
    let urls;

    if (bannerDetailsPage) {
      urls = data?.image?.map((item) => {
        if (typeof item === "string") {
          // Handle image links (array of strings)
          return item;
        }
        if (item instanceof File) {
          // Handle image files (array of File objects)
          return URL.createObjectURL(item);
        }
        return null; // Return null for non-matching items
      });

      setImageUrls(urls);
    }

    // Always return a cleanup function
    return () => {
      urls?.forEach((url) => {
        if (url && url.startsWith("blob:")) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [bannerDetailsPage, data?.image]);

  useEffect(() => {
    if (typeof data?.media !== "string") {
      const urls = data?.media?.map((item) => {
        if (typeof item === "string") {
          // Handle image links (array of strings)
          return item;
        }
        if (item instanceof File) {
          // Handle image files (array of File objects)
          return URL.createObjectURL(item);
        }
        return null; // Return null for non-matching items
      });

      setImageUrls(urls);

      // Clean up the URLs when the component is unmounted
      return () => {
        urls?.forEach((url) => {
          if (url && url.startsWith("blob:")) {
            URL.revokeObjectURL(url);
          }
        });
      };
    }
    return undefined; // Explicitly return undefined when there's no cleanup function to provide
  }, [data?.media]);

  useEffect(() => {
    if (data.details && typeof data.details === "string" && data.details !== "{}") {
      setMarkup(draftToHtml(JSON.parse(data?.details), hashtagConfig, true));
    } else if (
      data?.details &&
      typeof data.details === "object" &&
      data?.details?.blocks?.length > 0
    ) {
      const contentState = ContentState.createFromText(data?.details?.blocks[0]?.text);
      const editorState = EditorState.createWithContent(contentState);
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      setMarkup(draftToHtml(rawContentState, hashtagConfig));
    }
  }, [data.details]);

  useEffect(() => {
    if (
      data.disclaimerText &&
      typeof data.disclaimerText === "string" &&
      data.disclaimerText !== "{}"
    ) {
      setMarkup(draftToHtml(JSON.parse(data?.disclaimerText), hashtagConfig, true));
    } else if (
      data.disclaimerText &&
      typeof data.disclaimerText === "object" &&
      data.disclaimerText?.blocks?.length > 0
    ) {
      const contentState = ContentState.createFromText(data?.disclaimerText?.blocks[0]?.text);
      const editorState = EditorState.createWithContent(contentState);
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      setMarkup(draftToHtml(rawContentState, hashtagConfig));
    }
  }, [data.disclaimerText]);

  const checkType = (value, itemName) => {
    if (typeof value === "boolean" && itemName !== "disclaimer") {
      return (
        <Chip
          label={capitalizeFirstLetter(formatString(itemName))}
          href="#basic-chip"
          clickable
          key={itemName}
          sx={{
            fontSize: "0.8rem",
            padding: "0rem",
            borderRadius: "0.5rem",
            width: "fit-content",
            height: "fit-content",
          }}
        />
      );
    }
    if (itemName === "url") {
      return <CopytoClipboard text={value} />;
    }
    if (
      typeof value === "string" &&
      itemName !== "disclaimerText" &&
      itemName !== "details" &&
      itemName !== "Promocodes"
    ) {
      if (itemName === "dob" && value.length > 0) return format(new Date(value), "MM/dd/yyyy");
      if (itemName === "file" && value?.length > 0) {
        const url = new URL(value);
        const fileName = url?.pathname?.split("/")?.pop();
        return (
          <MDButton component="a" href={value} target="_blank" rel="noreferrer">
            {` View PDF - ${fileName}`}
          </MDButton>
        );
      }
      if (itemName === "logoName" && value?.length > 0) {
        const logoUrl = value === "Bus" ? Bus : value === "Train" ? Train : Taxi;
        return (
          <>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {value}
            </MDTypography>
            <BigAvatar
              sx={{ width: 100, height: 100, marginTop: 3, marginBottom: 1 }}
              alt="Logo"
              src={logoUrl}
              imgProps={{
                style: {
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "cover",
                },
              }}
            />
          </>
        );
      }
      // Display media URL as image if itemName is "media"
      if (
        itemName === "media" &&
        (value?.includes(".jpeg") ||
          value?.includes(".jpg") ||
          value?.includes(".png") ||
          value?.includes(".gif") ||
          value?.includes(".jfif"))
      ) {
        return <img src={value} alt="media" style={{ width: 300, height: "164px!important" }} />;
      }
      if (itemName === "ctaLink") {
        return (
          <span
            style={{
              display: "block",
              width: "100%",
              wordBreak: "break-all",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {value}
          </span>
        );
      }
      return value;
    }
    if (
      Array.isArray(value) &&
      (itemName === "images" || itemName === "media" || (itemName === "image" && bannerDetailsPage))
    ) {
      return (
        <ImageList cols={3} rowHeight={164} gap={16}>
          {imageUrls?.length > 0 &&
            imageUrls?.map((item, i) => (
              <ImageListItem key={i} sx={{ "& img": { height: "164px!important" } }}>
                <img
                  src={`${item}`}
                  srcSet={`${item}`}
                  alt={`images${i}`}
                  loading="lazy"
                  style={{ height: "164px!important", backgroundColor: "black" }}
                />
              </ImageListItem>
            ))}
        </ImageList>
      );
    }
    if (Array.isArray(value) && itemName === "openHours") {
      return <OpeningHoursList openHoursData={data?.openHours} />;
    }
    if (itemName === "Promocodes") {
      return (
        <ConciergePromocodes
          detailsPage
          key={user?._id}
          title="Promocodes"
          promoCodes={data?.Promocodes}
          user={userConcierge?.role === "concierge" ? userConcierge : user}
        />
      );
    }
    // if (Array.isArray(value) && itemName === "availabilities") {
    //   return (
    //     <>
    //       <MDTypography variant="h6" fontWeight="bold" textTransform="capitalize" mb={1}>
    //         {capitalizeFirstLetter(formatString(itemName))}
    //       </MDTypography>
    //       <AvailabilitiesList availabilitiesData={data?.availabilities} />
    //     </>
    //   );
    // }

    if (typeof value === "object" && itemName === "logistics") {
      return <PickupPointsList data={data?.logistics} />;
    }

    if (typeof value === "object" && itemName === "itinerary") {
      return <ItineraryItemsList data={data?.itinerary} />;
    }

    if (Array.isArray(value) && itemName === "languageGuides") {
      return <LanguagesDetailsList data={data?.languageGuides} />;
    }

    if (typeof value === "object" && itemName === "availabilities") {
      return <AvailabilitieSchedulesDetailsList data={data?.availabilities?.bookableItems} />;
    }

    if (Array.isArray(value) && itemName === "additionalInfo") {
      return (
        <ul style={{ paddingLeft: "20px" }}>
          {value.map((item, index) => (
            <li key={index} style={{ paddingLeft: "10px" }}>
              {item?.description || item}
            </li>
          ))}
        </ul>
      );
    }

    if (Array.isArray(value) && itemName === "sliders") {
      return <SlidersList data={data?.sliders} />;
    }
    if (
      Array.isArray(value) &&
      (itemName === "included" || itemName === "not_included" || itemName === "highlights")
    ) {
      return value.map((item) => (
        <Fragment key={item}>
          -{item}
          <br />
        </Fragment>
      ));
    }
    if (Array.isArray(value) && (itemName === "exclusions" || itemName === "inclusions")) {
      return value.map((obj) => obj?.type?.label || obj).join(", ");
    }
    if (
      (Array.isArray(value) && itemName !== "images") ||
      (Array.isArray(value) && itemName !== "included") ||
      (Array.isArray(value) && itemName !== "not_included") ||
      (Array.isArray(value) && itemName !== "highlights") ||
      (Array.isArray(value) && itemName !== "additionalInfo")
    ) {
      return value
        .map((obj) =>
          obj?.name
            ? obj?.name
            : obj?.title
            ? obj?.title
            : obj?.destinationName
            ? obj?.destinationName
            : obj?.typeDescription
            ? obj?.typeDescription
            : obj?.label || obj
        )
        .join(", ");
    }
    if (typeof value === "object" && value?.formatted_iso_value) {
      return value?.formatted_iso_value;
    }
    if (
      typeof value === "object" &&
      value?.url &&
      (itemName === "callToAction" || itemName === "callToAction2")
    ) {
      return `${value?.label} - ${value?.url}`;
    }
    if (typeof value === "object" && value !== null) {
      return value.name;
    }
    if (typeof value === "number") {
      return value;
    }
    return "--";
  };

  const checkAvailableQuantity = (value) => {
    if (value >= 0) {
      return value;
    }
    return "--";
  };

  return (
    <Card sx={{ boxShadow: "none", width: "100%" }}>
      {title.length > 0 && (
        <MDBox pb="12px">
          <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize">
            {title}
          </MDTypography>
        </MDBox>
      )}
      {Object.entries(data)?.map(
        ([itemName, itemValue], i) =>
          (itemValue?.length > 0 ||
            itemName === "Promocodes" ||
            itemName === "disclaimerText" ||
            itemName === "details" ||
            itemName === "logistics" ||
            itemName === "languageGuides" ||
            itemName === "availabilities" ||
            itemName === "itinerary" ||
            itemName === "additionalInfo" ||
            itemName === "destinations" ||
            itemValue?.name ||
            itemValue === true ||
            itemName === "lat" ||
            itemName === "lng" ||
            (itemName === "retailPrice" && itemValue?.value) ||
            (itemName === "callToAction" && itemValue?.label) ||
            (itemName === "callToAction2" && itemValue?.label) ||
            (itemName === "callToAction3" && itemValue?.label) ||
            (typeof itemValue === "number" && itemValue >= 0)) && (
            <MDBox key={i} display="flex" pb="4px" lineHeight={1.25} sx={{ overflow: "auto" }}>
              {itemName === "disclaimerText" && markup !== null ? (
                <MDBox display="flex">
                  <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                    Disclaimer:&nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    <div dangerouslySetInnerHTML={{ __html: markup }} />
                  </MDTypography>
                </MDBox>
              ) : (
                itemName !== "disclaimerText" && (
                  <MDBox
                    display="flex"
                    flexDirection={
                      itemName === "Promocodes" ||
                      itemName === "included" ||
                      itemName === "not_included" ||
                      itemName === "highlights"
                        ? "column"
                        : "row"
                    }
                  >
                    {
                      // itemName !== "availabilities" &&
                      itemName !== "logistics" &&
                        itemName !== "availabilities" &&
                        itemName !== "languageGuides" &&
                        itemName !== "itinerary" &&
                        itemName !== "additionalInfo" &&
                        typeof itemValue !== "boolean" &&
                        itemName !== "disclaimerText" &&
                        itemName !== "details" && (
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                            whiteSpace="nowrap"
                            pr="4px"
                          >
                            {itemName === "TRN"
                              ? "TRN"
                              : capitalizeFirstLetter(formatString(itemName))}
                            :
                          </MDTypography>
                        )
                    }
                    <MDTypography
                      component="span"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      style={{ display: "inline-block" }}
                    >
                      {itemName === "available_quantity"
                        ? checkAvailableQuantity(itemValue)
                        : checkType(itemValue, itemName) || checkType(itemValue, itemName) === 0
                        ? checkType(itemValue, itemName)
                        : "--"}
                    </MDTypography>
                  </MDBox>
                )
              )}
            </MDBox>
          )
      )}
    </Card>
  );
};

// Setting default props for the DetailList
DetailList.defaultProps = {
  title: "",
  conciergeDetailsPage: false,
  bannerDetailsPage: false,
};

// typechecking props for DetailList
DetailList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.instanceOf(Object).isRequired,
  conciergeDetailsPage: PropTypes.bool,
  bannerDetailsPage: PropTypes.bool,
};

export default DetailList;
