import React from "react";
import { Body, Container, Html, Text, Section } from "@react-email/components";

import { defaultProps, propTypes } from "./Proptypes";

import FooterSection from "../../FooterSection";
import LogoSection from "../../LogoSection/index";

const ClientReservationCancelled = ({ data }) => {
  /* **** Constants **** */
  const { guests = 0, time = "", date = "", name = "", phone = "", email = "" } = data;

  const handleDetails = (field, value) => (
    <Text
      numberOfLines={1}
      style={{
        margin: "0",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "26.5px",
      }}
    >
      {field}: {value}
    </Text>
  );

  return (
    <Html>
      <Body
        style={{
          display: "flex",
          color: "#222D29",
          alignItems: "center",
          fontFamily: "Helvetica",
          justifyContent: "center",
          backgroundColor: "#f7fafc",
        }}
      >
        <Container
          style={{
            maxWidth: "600px",
          }}
        >
          <LogoSection />

          <Text
            style={{
              fontSize: "24px",
              fontWeight: "400",
              lineHeight: "26.5px",
              padding: "30px 32px",
            }}
          >
            The reservation under the following details has just been cancelled by the guest.
          </Text>

          <div style={{ padding: "0px 24px", marginBottom: "34px" }}>
            <Section
              style={{
                borderRadius: "12px",
                padding: "16px 24px",
                fontFamily: "Helvetica",
                backgroundColor: "#F2F2F2",
              }}
            >
              <Text
                style={{
                  fontSize: "22px",
                  fontWeight: "700",
                  marginBottom: "12px",
                  lineHeight: "25.75px",
                }}
              >
                Reservation Details
              </Text>

              <div
                style={{
                  gap: "8px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {handleDetails("Guest Name", name)}
                {handleDetails("Phone Number", phone)}
                {handleDetails("Guest Email", email)}
              </div>

              <div style={{ border: "1px solid #BAB9B9", margin: "16px 0" }} />

              <Text
                style={{
                  fontSize: "22px",
                  fontWeight: "700",
                  marginBottom: "12px",
                  lineHeight: "25.75px",
                }}
              >
                Guest Details
              </Text>

              <div
                style={{
                  gap: "8px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {handleDetails("Reservation Count", guests)}
                {handleDetails("Reservation Time", time)}
                {handleDetails("Reservation Date", date)}
              </div>

              <p
                style={{
                  width: "90%",
                  fontSize: "20px",
                  fontWeight: "700",
                  marginTop: "16px",
                  lineHeight: "23px",
                }}
              >
                For any follow-up inquiries, kindly contact the guest directly or through the email
                address provided.
              </p>
            </Section>
          </div>

          <FooterSection hasSocialLinks={false} />
        </Container>
      </Body>
    </Html>
  );
};

ClientReservationCancelled.defaultProps = defaultProps;
ClientReservationCancelled.propTypes = propTypes;

export default ClientReservationCancelled;
