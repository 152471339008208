// import React from "react";
// import PropTypes from "prop-types";
// import { Body, Container, Html, Img, Section, Text, Row, Column } from "@react-email/components";
// import { Tailwind } from "@react-email/tailwind";
// import FooterSection from "../FooterSection/index";
// import IfYouNeedSupportSection from "../IfYouNeedSupport";
// import LogoSection from "../LogoSection";
// import HeadSection from "../HeadSection";

// const WelcomeEmail = ({ user, token }) => (
//   <Html>
//     <HeadSection />
//     <Tailwind>
//       <Body className="bg-gray-100  leading-relaxed">
//         <Container className="bg-white my-0 mx-auto p-0 max-w-[600px] border border-[#eaeaea]">
//           <LogoSection />
//           <Section className="justify-center pt-5 pb-5 text-center px-8">
//             <Img
//               src="https://email-assets.lokalee.app/email/emailImages/Image.png"
//               alt="Welcome"
//               className="max-w-full h-auto"
//             />
//           </Section>
//           <Section className="px-5 text-center">
//             {/* {line height:leading ,font weight: font-bold(700)/normal(600),text-size:text-xl text-[24px]   } */}
//             <Text className="text-[24px] leading-[34px] font-normal mb-2 ">
//               Greetings {user.name},
//             </Text>
//             <Text className="text-[28px] leading-[34px] font-bold mb-2">Welcome to Lokalee!</Text>
//           </Section>
//           <Section className="bg-[#F2F2F2] px-5 text-center w-500px h-46px border-b-4 border-[#394A43]">
//             <Text className="text-[20px] leading-[23px] font-bold">
//               We are happy to have you onboard!
//             </Text>
//             <Text className="text-[20px] leading-[23px] font-bold">
//               Please use the following code to validate your email:
//             </Text>
//             <Text className="text-[25px] leading-[26.5px] font-bold mb-5">{token}</Text>
//             <Text className="text-[20px] leading-[23px] font-bold mb-5">
//               Thanks,
//               <Row>
//                 <Column className="text-[20px] leading-[23px] font-bold mb-5">Lokalee Team</Column>
//               </Row>
//             </Text>
//           </Section>
//           <hr className="border-t-0  border-[#394A43]" />
//           <IfYouNeedSupportSection />
//           <FooterSection />
//         </Container>
//       </Body>
//     </Tailwind>
//   </Html>
// );
// WelcomeEmail.defaultProps = {
//   user: {
//     name: "Lokalee",
//   },
//   token: "961",
// };

// WelcomeEmail.propTypes = {
//   user: PropTypes.shape({
//     name: PropTypes.string,
//   }),
//   token: PropTypes.string,
// };

// export default WelcomeEmail;

import React from "react";
import PropTypes from "prop-types";
import { Body, Container, Html, Img, Section, Text } from "@react-email/components";
import FooterSection from "../FooterSection/index";
import IfYouNeedSupportSection from "../IfYouNeedSupport";
import LogoSection from "../LogoSection";
import HeadSection from "../HeadSection";

const WelcomeEmail = ({ user, token }) => (
  <Html>
    <HeadSection />
    <Body
      style={{
        backgroundColor: "#f7fafc", // Equivalent to 'bg-gray-100'
        lineHeight: "1.625", // Equivalent to 'leading-relaxed'
      }}
    >
      <Container
        style={{
          backgroundColor: "#ffffff", // 'bg-white'
          marginTop: "0", // 'my-0'
          marginBottom: "0", // 'my-0'
          marginLeft: "auto", // 'mx-auto'
          marginRight: "auto", // 'mx-auto'
          padding: "0", // 'p-0'
          maxWidth: "600px", // 'max-w-[600px]'
          border: "1px solid #eaeaea", // 'border border-[#eaeaea]'
        }}
      >
        <LogoSection />
        <Section
          style={{
            paddingTop: "1.25rem", // 'pt-5'
            paddingBottom: "1.25rem", // 'pb-5'
            textAlign: "center", // 'text-center'
            paddingLeft: "2rem", // 'px-8' (left)
            paddingRight: "2rem", // 'px-8' (right)
          }}
        >
          <Img
            src="https://email-assets.lokalee.app/email/emailImages/Image.png"
            alt="Welcome"
            style={{
              maxWidth: "100%", // 'max-w-full'
              height: "auto", // 'h-auto'
            }}
          />
        </Section>
        <Section
          style={{
            paddingLeft: "1.25rem", // 'px-5' (left)
            paddingRight: "1.25rem", // 'px-5' (right)
            textAlign: "center", // 'text-center'
          }}
        >
          <Text
            style={{
              fontSize: "24px", // 'text-[24px]'
              lineHeight: "34px", // 'leading-[34px]'
              fontWeight: "400", // 'font-normal'
              marginBottom: "0.5rem", // 'mb-2'
            }}
          >
            Greetings {user.name},
          </Text>
          <Text
            style={{
              fontSize: "28px", // 'text-[28px]'
              lineHeight: "34px", // 'leading-[34px]'
              fontWeight: "700", // 'font-bold'
              marginBottom: "0.5rem", // 'mb-2'
            }}
          >
            Welcome to Lokalee!
          </Text>
        </Section>
        <Section
          style={{
            backgroundColor: "#F2F2F2", // Background color
            paddingLeft: "1.25rem", // Left padding
            paddingRight: "1.25rem", // Right padding
            textAlign: "center", // Text center alignment
            width: "100%", // Ensure full width
            boxSizing: "border-box", // Ensure padding is included in the element's width and height
            maxWidth: "600px", // Set a maximum width for the section
            margin: "0 auto", // Center the section horizontally
            borderBottom: "0.5px solid #394A43", // Border at the bottom
          }}
        >
          <Text
            style={{
              fontSize: "20px", // Text size
              lineHeight: "23px", // Line height
              fontWeight: "700", // Bold text
              marginBottom: "1rem", // Bottom margin
            }}
          >
            We are happy to have you onboard!
          </Text>
          <Text
            style={{
              fontSize: "20px",
              lineHeight: "23px",
              fontWeight: "700",
              marginBottom: "1rem",
            }}
          >
            Please use the following code to validate your email:
          </Text>
          <Text
            style={{
              fontSize: "25px",
              lineHeight: "26.5px",
              fontWeight: "700",
              marginBottom: "1.25rem",
            }}
          >
            {token}
          </Text>
          <Text
            style={{
              fontSize: "20px",
              lineHeight: "23px",
              fontWeight: "700",
            }}
          >
            Thanks,
            <br />
            Lokalee Team
          </Text>
        </Section>

        <hr
          style={{
            border: "none",
            borderTop: "0",
            borderBottom: "0.5px solid #394A43",
          }}
        />

        <IfYouNeedSupportSection />
        <FooterSection />
      </Container>
    </Body>
  </Html>
);

WelcomeEmail.defaultProps = {
  user: {
    name: "Lokalee",
  },
  token: "961",
};

WelcomeEmail.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
  token: PropTypes.string,
};

export default WelcomeEmail;
