/* eslint-disable react/require-default-props */
// Import statements
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import Icon from "@mui/material/Icon";
import { Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import { clearFilters } from "store/actions/userActions";
import MDAvatar from "components/MDAvatar";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { item, itemContent, itemArrow } from "./styles/sidenavItem";

const SidenavItem = ({
  color = "info",
  active = false,
  nested = false,
  children = null,
  open = false,
  icon = "dashboard",
  isUser = false,
  name,
  ...rest
}) => {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (active) {
      window.location.reload();
    } else {
      dispatch(clearFilters());
    }
  };

  // Access theme properties for styling
  const muTheme = useTheme();
  const { palette, typography, transitions } = muTheme;
  const { white, dark } = palette;
  const { size, fontWeightRegular, fontWeightLight } = typography;

  // Determine icon color based on sidenav mode
  const iconColor =
    ((transparentSidenav && !darkMode) || whiteSidenav) && (active === "isParent" || !active)
      ? dark.main
      : white.main;

  return (
    <>
      <ListItem
        {...rest}
        onClick={handleOnClick}
        component="li"
        sx={(theme) => item(theme, { active, color, transparentSidenav, whiteSidenav, darkMode })}
      >
        <MDBox
          sx={(theme) =>
            itemContent(theme, {
              active,
              miniSidenav,
              name,
              open,
              nested,
              transparentSidenav,
              whiteSidenav,
              darkMode,
            })
          }
        >
          {isUser ? (
            <MDAvatar alt="User Avatar" size="sm" src={icon} />
          ) : (
            <Icon sx={{ pr: 4, pl: 1, color: iconColor }}>{icon}</Icon>
          )}

          <Tooltip title={name} arrow placement="right">
            <Typography
              noWrap
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginLeft: isUser ? "8px" : "0",
                flexGrow: 1,
                minWidth: 0,
                color:
                  ((transparentSidenav && !darkMode) || whiteSidenav) &&
                  (active === "isParent" || !active)
                    ? dark.main
                    : white.main,
                fontWeight: active ? fontWeightRegular : fontWeightLight,
                fontSize: size.sm,
                opacity: miniSidenav ? 0 : 1,
                transition: transitions.create(["opacity", "color"], {
                  easing: transitions.easing.easeInOut,
                  duration: transitions.duration.standard,
                }),
              }}
            >
              {name}
            </Typography>
          </Tooltip>

          {children && (
            <Icon
              component="i"
              sx={(theme) => ({
                color: iconColor,
                ...itemArrow(theme, {
                  open,
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  darkMode,
                }),
              })}
            >
              expand_less
            </Icon>
          )}
        </MDBox>
      </ListItem>

      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit {...rest}>
          {children}
        </Collapse>
      )}
    </>
  );
};

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "primaryC",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  name: PropTypes.string.isRequired,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
  isUser: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default SidenavItem;
