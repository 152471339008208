const badgesList = [
  // { label: "Insider tip", value: "insider" },
  { label: "New", value: "new" },
  { label: "Exclusive", value: "exclusive" },
  // { label: "Top seller", value: "top_seller" },
  { label: "Likely to Sell out", value: "likely_to_sell_out" },
  // { label: "Excellent Quality", value: "excellent_quality" },
  // { label: "Best Value", value: "best_value" },
  { label: "Best Seller", value: "best_seller" },
  // { label: "Once in a lifetime", value: "once_in_a_lifetime" },
  { label: "Special offer", value: "special_offer" },
];

export default badgesList;
