/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */

// Material Dashboard 2 PRO React examples

// libraries
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import {
  addYears,
  endOfDay,
  endOfYear,
  format,
  isSameDay,
  startOfDay,
  startOfYear,
  subDays,
  subYears,
} from "date-fns";

// custom components
import Spinner from "components/Spinner";
import Danger from "components/Danger";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DynamicDateRange from "components/DynamicDateRange";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDButton from "components/MDButton";
import MaterialTablePicker from "components/MaterialTablePicker";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";

// redux actions
import {
  addLocationReport,
  getHeaderReport,
  setLoading,
  clearHeaderAndReports,
} from "store/actions/reportActions";
import LocationReportPicker from "components/AutoComplete/ReportsPickers/LocationReportPicker";
import { getLocations, getUserPartnerLocations } from "store/actions/locationAction";

// material ui components
import { Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid2";

// Schemas
import { defaultStaticRanges } from "react-date-range";
import initialValues from "./LocationReportsList/schemas/initialValues";
import validations from "./LocationReportsList/schemas/validations";
import CsvLocationReport from "../CsvLocationReports";

const LocationReports = () => {
  //   const { formField, values, errors, touched, setFieldValue } = formData;
  //   const { title, location } = formField;
  //   const { location: locationV } = values;

  const tableRef = createRef();
  const dispatch = useDispatch();
  const formRef = useRef();

  const locationreports = useSelector((state) => state.reports.reports);
  const headerInfo = useSelector((state) => state.reports.headerInfo);
  const locations = useSelector((state) => state.locations.locations);
  const loading = useSelector((state) => state.reports.loading);
  const user = useSelector((state) => state.users.user);

  const currentValidation = validations[0];
  const [hideDaterange, setHideDaterange] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        7
      ),
      endDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        1
      ),
      key: "selection",
    },
  ]);
  const handleOptionClick = (option) => {
    setSelectedOption(option);

    setAnchorEl(null);
  };

  const handleFileDownloadIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedOption(null);
  };
  const filteredData = locationreports
    .at(-1)
    ?.result?.map((val, i) =>
      val?.Menus
        ? val?.Menus
        : val?.Recommendations
        ? val?.Recommendations
        : val?.Tickets
        ? val?.Tickets
        : val?.mainPage
    );
  const filteredTitles = locationreports
    .at(-1)
    ?.result?.map((val, i) =>
      val?.Menus
        ? "Menus"
        : val?.Recommendations
        ? "RECOMMENDATIONS"
        : val?.Tickets
        ? "TICKETS"
        : "MAIN PAGE"
    );

  const handleDownload = (option) => {
    if (option === "csv") {
      return <CsvLocationReport data={filteredData} titles={filteredTitles} />;
    }
    return null;
  };
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm = async (values, actions) => {
    await sleep(1000);

    dispatch(setLoading());
    dispatch(
      addLocationReport({
        dateRange,
        slug: selectedLocation?.slug,
      })
    );
    dispatch(
      getHeaderReport({
        dateRange,
        slug: selectedLocation?.slug,
      })
    );
    // actions.setSubmitting(false);
    // actions.resetForm();
  };

  const handleSubmit = (values, actions) => {
    if (!selectedLocation) {
      return;
    }
    submitForm(values, actions);
  };

  const filteredStaticRanges = defaultStaticRanges.filter(
    (staticRange) => staticRange.label !== "Today"
  );

  const selectedRanges = [
    ...filteredStaticRanges,
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          7
        ),
        endDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          1
        ),
      }),
      isSelected(range) {
        return (
          isSameDay(range.startDate, range.endDate) &&
          isSameDay(range.startDate, startOfDay(subDays(new Date(), 6))) &&
          isSameDay(range.endDate, endOfDay(new Date()))
        );
      },
    },
    {
      label: "This Year",
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: subDays(
          new Date(
            Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
          ),
          1
        ),
      }),
      isSelected(range) {
        return (
          isSameDay(range.startDate, range.endDate) &&
          isSameDay(range.startDate, startOfYear(new Date())) &&
          isSameDay(range.endDate, endOfDay(new Date()))
        );
      },
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: startOfYear(addYears(new Date(), -1)),
        endDate: endOfYear(addYears(new Date(), -1)),
      }),
      isSelected(range) {
        return (
          isSameDay(range.startDate, range.endDate) &&
          isSameDay(range.startDate, startOfYear(subYears(new Date(), 1))) &&
          isSameDay(range.endDate, endOfYear(subYears(new Date(), 1)))
        );
      },
    },
  ];

  const titleTable = (element, totalViews) => (
    <>
      <span
        style={{
          marginRight: "30px",
          fontWeight: "bold",
          fontSize: "23px",
          textAlign: "center",
        }}
      >
        {element}{" "}
        {element === "GROUPS" && (
          <span
            style={{
              marginRight: "15px",
              fontWeight: "Normal",
              fontSize: "16px",
              display: "inline-block",
            }}
          >
            (BOXES)
          </span>
        )}
      </span>

      <span style={{ textAlign: "center", fontSize: "13px", fontWeight: "normal" }}>
        Total Views: {totalViews}
      </span>
    </>
  );

  const handleDateChange = (date, event) => {
    if (date?.selection?.startDate && isSameDay(date.selection.startDate, date.selection.endDate)) {
      const newStartDate = date.selection.startDate;
      const newEndDate = dateRange[0].endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    } else {
      const newStartDate = date?.range1?.startDate
        ? date?.range1.startDate
        : date?.selection?.startDate;
      const newEndDate = date?.range1?.endDate ? date?.range1.endDate : date?.selection?.endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    }
  };

  useEffect(() => {
    setLoading();
    if (user?.role === "partner") {
      dispatch(getUserPartnerLocations(user?.partner?._id));
    } else {
      dispatch(getLocations());
    }

    // eslint-disable-next-line
  }, []);

  const handleLocationChange = (value) => {
    if (!value) {
      setSelectedLocation(null);
    } else {
      setSelectedLocation(value);
    }
  };

  useEffect(() => {
    dispatch(clearHeaderAndReports());
  }, [selectedLocation]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid item size={{ xs: 12, lg: 8 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidation}
          onSubmit={handleSubmit}
          innerRef={(f) => {
            formRef.current = f;
          }}
        >
          {({ values, errors, touched, isSubmitting, formData, setFieldValue }) => (
            <Form autoComplete="off">
              <Grid container spacing={3}>
                {" "}
                <Grid item style={{ marginTop: 3 }} size={{ xs: 6 }}>
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    Location
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  >
                    <LocationReportPicker
                      locations={locations}
                      setFieldValue={setFieldValue}
                      location={selectedLocation}
                      locationReportPage
                      handleChange={handleLocationChange}
                    />
                  </MDBox>
                  {errors?.location && touched?.location && (
                    <Danger validation={errors?.location} />
                  )}
                </Grid>
                <Grid style={{ marginTop: "28px" }} item xs={6}>
                  <DynamicDateRange
                    defaultValue={dateRange}
                    selectedOption={dateRange}
                    selectedRanges={selectedRanges}
                    handleDateChange={handleDateChange}
                    hideDaterange={hideDaterange}
                    hideDateRangeFn={setHideDaterange}
                  />
                  <MDBox style={{ fontSize: "small" }}>
                    Selected Date Range: {dateRange[0]?.startDate.toDateString()} to{" "}
                    {dateRange[0].endDate.toDateString()}
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "right",
                  // marginRight: "45px",
                  marginTop: "20px",
                }}
              >
                {" "}
                <div style={{ marginTop: "20px" }}>
                  <MDButton
                    onClick={(val, actions) => {
                      if (Object.keys(errors).length === 0) {
                        setHideDaterange(true);
                      }
                    }}
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    Generate report
                  </MDButton>
                </div>
              </MDBox>
              {selectedLocation && locationreports.length > 0 && (
                <div
                  style={{
                    width: "100%",
                    height: "21px",
                    marginTop: "15px",
                    backgroundColor: "#d3d3d3",
                  }}
                />
              )}
              {headerInfo.headerReport && selectedLocation !== null && (
                <MDBox sx={{ mt: 5 }} style={{ textAlign: "center" }}>
                  <div>
                    <h3
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "bold",
                      }}
                    >
                      {headerInfo.headerReport?.pageTitle}
                    </h3>
                    <h4
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "normal",
                      }}
                    >
                      Traffic and Views Report
                    </h4>
                    <h6
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "normal",
                      }}
                    >
                      {headerInfo.headerReport?.startDate &&
                        format(new Date(headerInfo.headerReport?.startDate), "dd/MM/yyyy")}{" "}
                      /{" "}
                      {headerInfo.headerReport?.endDate &&
                        format(new Date(headerInfo.headerReport?.endDate), "dd/MM/yyyy")}
                    </h6>
                  </div>
                  <div
                    style={{
                      //   textAlign: "center",
                      marginTop: "60px",
                    }}
                  >
                    <span
                      style={{
                        float: "left",
                        textAlign: "center",
                        marginLeft: "75px",
                        // fontSize: "0.67em",
                        fontWeight: "bold",
                      }}
                    >
                      Total views: {headerInfo.headerReport?.views}{" "}
                    </span>
                    <span
                      style={{
                        // textAlign: "center",
                        // fontSize: "0.67em",
                        fontWeight: "bold",
                      }}
                    >
                      New users: {headerInfo.headerReport?.newUsers}{" "}
                    </span>
                    <span
                      style={{
                        textAlign: "center",
                        float: "right",
                        marginRight: "75px",
                        // fontSize: "0.67em",
                        fontWeight: "bold",
                      }}
                    >
                      Total number of users: {headerInfo.headerReport?.totalUsers}{" "}
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    <span
                      style={{
                        float: "left",
                        textAlign: "center",
                        marginLeft: "75px",
                        fontSize: "initial",
                      }}
                    >
                      Logins: {headerInfo.logins}{" "}
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "8px",
                      marginBottom: "10px",
                      clear: "left",
                      fontSize: "initial",
                    }}
                  >
                    <span
                      style={{
                        float: "left",
                        textAlign: "center",
                        marginLeft: "75px",

                        // fontSize: "1.2em",
                      }}
                    >
                      Signups: {headerInfo.signups}{" "}
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        float: "right",
                        textAlign: "center",
                        marginRight: "75px",
                      }}
                    >
                      <div>
                        <FileDownloadIcon
                          style={{ cursor: "pointer", width: "30px", height: "30px" }}
                          onClick={handleFileDownloadIconClick}
                        >
                          <MoreVertIcon />
                        </FileDownloadIcon>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                        >
                          {/* <MenuItem
                            onClick={() => {
                              handleOptionClick("pdf");
                            }}
                          >
                            Download as PDF
                          </MenuItem> */}
                          <MenuItem
                            onClick={() => {
                              handleOptionClick("csv");
                            }}
                          >
                            Download as CSV
                          </MenuItem>
                        </Menu>
                      </div>
                    </span>
                  </div>
                </MDBox>
              )}

              <Grid item size={{ xs: 12 }} mt={3}>
                {locationreports.length > 0 &&
                  selectedLocation !== null &&
                  locationreports[0].result?.length > 0 &&
                  locationreports.at(-1)?.result?.map((val, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MDBox sx={{ m: 5 }} key={i}>
                      <MaterialTablePicker
                        screen="locationReportsList"
                        title={titleTable(
                          val?.Menus
                            ? "Menus"
                            : val?.Recommendations
                            ? "RECOMMENDATIONS"
                            : val?.Items
                            ? "TICKETS"
                            : "MAIN PAGE",

                          val?.Menus
                            ? val?.totalViews
                            : val?.Recommendations
                            ? val?.totalViews
                            : val?.Items
                            ? val?.totalViews
                            : val?.mainPage[0]?.views
                        )}
                        currentUrl="locationReports"
                        tableRef={tableRef}
                        columns={[
                          "",
                          "Views",
                          "Engagement",
                          "Page views per session",
                          "Users",
                          "Abandon rate",
                        ]}
                        data={
                          val?.Menus
                            ? val?.Menus
                            : val?.Recommendations
                            ? val?.Recommendations
                            : val?.Items
                            ? val?.Items
                            : val?.mainPage
                        }
                        isLoading={loading}
                        filteringOptions={false}
                        isSearchable={false}
                        isExportable={false}
                        actionsName="LocationReport"
                        isRowClickable={false}
                      />
                    </MDBox>
                  ))}
                {locationreports.length > 0 &&
                  selectedLocation !== null &&
                  locationreports[0].result?.length > 0 && (
                    <MDBox sx={{ m: 10 }}>
                      {" "}
                      <span>
                        <div
                          style={{
                            textAlign: "left",
                            marginTop: "8px",
                            fontWeight: "bold",
                            marginBottom: "-30px",
                            clear: "left",
                          }}
                        >
                          <span
                            style={{
                              marginLeft: "-38px",
                              fontWeight: "bold",
                              fontSize: "23px",
                              textAlign: "left",
                            }}
                          >
                            OTHER PAGES:{" "}
                            {locationreports[locationreports.length - 1]?.otherPagesResult
                              ?.otherPages !== null
                              ? locationreports[locationreports.length - 1].otherPagesResult
                                  .otherPages
                              : 0}{" "}
                          </span>
                        </div>
                      </span>
                    </MDBox>
                  )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>

      <Footer />
      <Spinner loading={loading} />
      {selectedOption && handleDownload(selectedOption)}
    </DashboardLayout>
  );
};

export default LocationReports;
